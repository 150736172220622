const Contact = () => {
  return (
    <section id='contact'>
      <div className='container'>
        <h2>Contact</h2>
      </div>
    </section>
  );
};

export default Contact;
